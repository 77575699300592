import Quill from 'quill';
import { nanoid } from 'nanoid';

const Inline = Quill.import('blots/inline');

class CommentBlot extends Inline {
  static create(commentText) {
    const node = super.create(commentText);
    node.dataset.comment = commentText.comment;
    if (commentText.id) {
      node.dataset.id = commentText.id;
    }
    if (commentText.author) {
      node.dataset.author = commentText.author;
    }

    return node;
  }
  static formats(node) {
    return node.dataset;
  }
  format(name, value) {
    super.format(name, value);
  }
}

(CommentBlot as any).blotName = 'comment';
(CommentBlot as any).tagName = 'SPAN';
(CommentBlot as any).className = 'annotation';

class InlineComment {
  public quill: any;
  public toolbar: any;

  constructor(quill) {
    this.quill = quill;
    this.toolbar = quill.getModule('toolbar');
    if (typeof this.toolbar != 'undefined')
      this.toolbar.addHandler('comment', this.commentEventHandler);

    const commentBtns = document.getElementsByClassName('ql-comment');
    if (commentBtns) {
      [].slice.call(commentBtns).forEach(function (commentBtn) {
        commentBtn.innerHTML =
          '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16"><g fill="none" fill-rule="evenodd"><path fill="#ccc" fill-rule="nonzero" d="M9.92 11H13c1.66 0 3-1.36 3-3V5c0-1.66-1.34-3-3-3H5C3.34 2 2 3.34 2 5v3c0 1.64 1.34 3 3 3h1.44l.63 1.88 2.85-1.9zM5 0h8c2.76 0 5 2.24 5 5v3c0 2.75-2.24 5-5 5h-2.47L7.1 15.26c-.47.3-1.1.2-1.4-.27-.05-.1-.08-.18-.1-.26L5 13c-2.76 0-5-2.25-5-5V5c0-2.76 2.24-5 5-5z"/><path stroke="#444" stroke-width="2" d="M5.37 5H13M5.37 8H10" stroke-linecap="round" stroke-linejoin="round"/></g></svg>';
      });
    }
  }

  commentEventHandler() {
    const quill = this.quill;
    checkDialogExist(quill);
  }
}

function checkDialogExist(quill) {
  const commentToolTip = document.getElementById('inline-comment');
  if (commentToolTip) {
    commentToolTip.remove();
  } else {
    createCommentDialog(quill);
  }
}

function createCommentDialog(quill) {
  const range = quill.getSelection();
  const text = quill.getText(range.index, range.length);
  if (text.length < 1) {
    return;
  }
  const atSignBounds = quill.getBounds(range.index);

  const container = document.createElement('div');
  container.id = 'inline-comment';
  container.classList.add('inline-comment');
  quill.container.appendChild(container);
  container.style.position = 'absolute';
  container.innerHTML =
    '<textarea class="commentText" placeholder="Comment"></textarea><div class="inline-comment-bottom"><span class="inline-cancel cursor-pointer">Cancel</span> <span class="inline-send cursor-pointer">Send</span> </div>';

  container.style.left = atSignBounds.left - 250 + 'px';

  if (atSignBounds.left + 250 < quill.container.clientWidth) {
    container.style.left = atSignBounds.left + 'px';
  }

  container.style.top = 10 + atSignBounds.top + atSignBounds.height + 'px';
  container.style.zIndex = '80';

  (document.querySelector('.commentText') as HTMLTextAreaElement).focus();

  const inlineCancel = document.querySelector('.inline-cancel');
  const commentToolTip: HTMLDivElement =
    document.querySelector('.inline-comment');

  inlineCancel.addEventListener('click', function () {
    commentToolTip.style.display = 'none';
  });

  const inlineSend = document.querySelector('.inline-send');

  inlineSend.addEventListener('click', function () {
    const commentText = (
      document.querySelector('.commentText') as HTMLTextAreaElement
    ).value;
    console.log('Send', commentText);
    const commentObj: {
      comment: string;
      id: string;
      author?: string;
    } = {
      id: nanoid(8),
      comment: commentText,
    };
    // if (typeof commentId !== 'undefined') {
    //   commentObj.id = commentId;
    // }
    // if (typeof commentAuthor !== 'undefined') {
    //   commentObj.author = commentAuthor;
    // }
    commentToolTip.remove();
    quill.formatText(range.index, range.length, {
      comment: commentObj,
    });
  });
}

export { CommentBlot, InlineComment };
