



















import Vue from 'vue';
import Quill from 'quill';
import { CommentBlot, InlineComment } from './quill-comments';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';

import { quillEditor } from 'vue-quill-editor';
import { debounce } from 'lodash/fp';

Quill.register({
  'formats/comment': CommentBlot,
});
Quill.register('modules/inline_comment', InlineComment);

export default Vue.extend({
  name: 'QuillWrapper',
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    editingOptions: {
      type: String,
      default: 'advanced',
    },
    bounds: {
      type: [String, Element],
      default: '.quill-wrapper',
    },
    targetFormat: {
      type: String,
      default: 'html',
    },
  },
  data() {
    return {
      isInitialized: false,
      html: '',
      text: '',
      buffer: '',
      lastSavedValue: '',
      isDirty: false,
    };
  },
  computed: {
    toolbarOptions() {
      if (this.editingOptions == 'advanced') {
        return [
          ['bold', 'italic', 'underline', 'strike', 'code'],
          [{ script: 'sub' }, { script: 'super' }],
          [{ list: 'ordered' }, { list: 'bullet' }, 'blockquote', 'code-block'],
          [{ align: [] }],
          ['link'],
          ['comment'],
          ['clean'], // remove formatting button
        ];
      }
      if (this.editingOptions == 'inline') {
        return [
          ['bold', 'italic', 'underline'],
          [{ script: 'sub' }, { script: 'super' }],
          ['link'],
          ['clean'], // remove formatting button
        ];
      }
      if (this.editingOptions == 'basic') {
        return [
          ['bold', 'italic', 'underline'],
          [{ script: 'sub' }, { script: 'super' }],
          [{ list: 'ordered' }, { list: 'bullet' }, 'blockquote'],
          ['link'],
          ['clean'], // remove formatting button
        ];
      }
      return [];
    },
    editorOptions() {
      return {
        ...{
          theme: 'bubble',
          bounds: this.bounds,
          modules: {
            toolbar: {
              container: this.toolbarOptions,
              handlers: { comment: function () {} },
            },
            inline_comment: true,
          },
          placeholder: this.placeholder || this.$t('list.placeholder'),
        },
        ...this.options,
      };
    },
    editor() {
      return this.$refs.quillEditorWrapper.quill;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue == this.lastSavedValue) return;
        if (this.isDirty) return;
        this.buffer = newValue;
      },
    },
  },
  created() {
    this.debouncedUpdate = debounce(150, () => {
      this.$emit('updateContent', {
        html: this.html,
        text: this.text,
      });

      this.lastSavedValue =
        this.targetFormat === 'text' ? this.text : this.html;
    });
  },
  mounted() {
    if (this.focusOnMount) {
      setTimeout(() => {
        this.editor.focus();
        this.isDirty = true;
      }, 200);
    }
  },
  beforeDestroy() {
    this.$emit('updateContent', { html: this.html, text: this.text });
    this.$emit('blur', { html: this.html, text: this.text });
  },
  methods: {
    focus() {
      (this as any).editor.focus();
    },
    onEditorBlur($event) {
      this.$emit('updateContent', {
        html: (this as any).html,
        text: (this as any).text,
      });
      this.$emit('blur', {
        html: (this as any).html,
        text: (this as any).text,
      });
      (this as any).isDirty = false;
    },
    onEditorFocus($event) {
      this.$emit('focus', $event);
      (this as any).isDirty = true;
    },
    onEditorReady(quill) {
      (this as any).html = quill.root.innerHTML;
      (this as any).text = quill.getText();
    },
    onEditorChange({ html, text }: { html: string; text: string }) {
      // console.log('Editor changed', (this as any).targetFormat, text, html);
      this.$emit('input', html);
      (this as any).debouncedUpdate();
      const countBefore = ((this as any).html.match(/annotation/g) || [])
        .length;
      const countAfter = (html.match(/annotation/g) || []).length;
      (this as any).html = html;
      (this as any).text = text;
      this.$emit('updateContent', { html, text });
      if (countBefore != countAfter) {
        (this as any).onEditorBlur();
      }
    },
  },
});
